<template>
  <v-container>
    <v-dialog
      overlay-opacity="0.8"
      transition="dialog-bottom-transition"
      v-model="dialog_new"
      @keydown.esc="dialog_new = false"
      persistent
      max-width="330px"
    >
      <template data-test="vAddUsuario" v-slot:activator="{ on }">
        <v-btn
          data-test="vAddUsuario"
          x-large
          style="margin-top: -10px; margin-left: 10px"
          text
          dark
          color="secondary"
          v-on="on"
        >
          {{$t('portada.buscador')}}
          <v-icon data-test="vAddUsuario" dark>mdi-magnify</v-icon>
        </v-btn>
      </template>
      <base-material-card
        color="primary"
        inline
        title="Consulta cobertura"
      >
        <v-form
          class="pt-1"
          style="
            overflow-y: hidden !important;
            overflow-x: hidden !important;
          "
        >
          <v-row >
            <v-col >
              <v-text-field
                outlined
                dense
                style="margin-top: 23px"
                type="number"
                v-model="cp"
                :counter="5"
                label="Código Postal"
                required
              ></v-text-field>
              <model-list-select
                outlined
                dense
                :list="calles"
                v-model="calleSeleccionada"
                option-value="nombreConcatenado"
                option-text="nombreConcatenado"
                class="form-datos-cliente-border"
                :placeholder="$t('orderForm.calle')"
                @select="onSelectCalle"
                :isDisabled="this.isDisabledList"
              >
              </model-list-select>
              <v-select
                outlined
                dense
                style="margin-top: 25px"
                v-model="selectNumeroCalle"
                :items="arrayNumerosCalles"
                item-text="valor"
                item-value="valor"
                label="Número"
                return-object
                @change="onSelectNumeroCalle"
                :disabled="this.bloquearSelectorNumero"
              ></v-select>
            </v-col>
          </v-row>
          <v-snackbar
            v-model="snackbar"
            :bottom="true"
            :right="true"
            :timeout="snack_time"
            :color="snack_color"
          >
            {{ snack_text }}
            <v-btn dark text @click="snackbar = false">Cerrar</v-btn>
          </v-snackbar>
          <v-card-actions style="padding-top: 10px">
            <v-spacer></v-spacer>
            <v-btn
              data-test="vDeleteSalir"
              color="primary"
              text
              @click="salir()"
              >Salir
            </v-btn>
            <v-btn
              data-test="vDeleteSalir"
              color="primary"
              v-show="contratarShow"
              text
              @click="contratar()"
              >Contratar
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'

export default {
    components: {
        ModelListSelect,
        'base-material-card': () => import('./MaterialCard.vue')
    },
    data () {
        return {
            loginAlert: false,
            dialog_new: false,
            espera: 6000,
            date: null,
            tipoVial: '',
            isDisabledList: true,
            nombre: '',
            cp: '',
            snackbar: false,
            snack_color: '',
            snack_text: '',
            snack_time: -1,
            url: process.env.VUE_APP_API_URL,
            ocultarBotonCalleNumeroFormulario: false,
            bloquearSelectorNumero: true,
            calles: [],
            arrayNumerosCalles: [],
            arrayDirecciones: [],
            calleSeleccionada: {},
            selectNumeroCalle: '',
            contratarShow: false
        }
    },
    computed: {
        CambiarCp () {
            return this.cp
        },

        CambioCalle () {
            return this.calleSeleccionada
        }
    },
    watch: {
        CambiarCp (value) {
            this.comprobarCP()
        },
        CambioCalle (value) {
            this.onSelectCalle(value)
        }
    },
    methods: {
        salir () {
            this.contratarShow = false
            this.cp = ''
            this.arrayNumerosCalles = []
            this.arrayDirecciones = []
            this.calleSeleccionada = {}
            this.calleSeleccionada = {}
            this.bloquearSelectorNumero = true
            this.ocultarBotonCalleNumeroFormulario = false
            this.calles.push()
            this.reset()
            this.isDisabledList = true
            this.dialog_new = false
        },
        contratar () {
            this.$router.push('/pedido')
        },

        go (msg, color, time) {
            this.snack_time = time
            this.snack_text = msg
            this.snack_color = color
            this.snackbar = true
        },
        onSelectNumeroCalle () {
            this.contratarShow = true

            this.go(this.$t('orderForm.errores.cobertura'), 'primary')
        },

        onSelectCalle (calleSeleccionada) {
            this.arrayDirecciones = []
            this.ocultarBotonCalleNumeroFormulario = false
            this.arrayNumerosCalles = []
            if (calleSeleccionada.nombre) {
                this.calleSeleccionada = calleSeleccionada
                this.arrayNumerosCalles = []
                if (this.calleSeleccionada.nombre) {
                    this.listarNumerosCalle()
                }
            } else {
                this.bloquearSelectorNumero = true
                this.ocultarBotonCalleNumeroFormulario = false
            }
        },
        listarNumerosCalle () {
            const poblacion = this.calleSeleccionada.poblacion
            this.bloquearSelectorNumero = false
            this.poblacion = this.calleSeleccionada.poblacion
                .trim()
                .replace(/\s+/g, '%20')
            const calle = this.calleSeleccionada.nombre.trim().replace(/\s+/g, '%20')
            const golistarNumerosCalle = async () => {
                const config = {
                    timeout: this.espera,
                    headers: {
                        Accept: 'application/json'
                    }
                }
                try {
                    return await this.$axios.get(
                        this.url + 'cobertura/poblacion/' + poblacion + '/calle/' + calle,
                        config
                    )
                } catch (error) {
                    this.contratarShow = false

                    this.go(this.$t('errorConexionApi'), 'error')
                }
            }

            const processListarNumerosCalle = async () => {
                const listarNumerosCalle_result = await golistarNumerosCalle()
                if (listarNumerosCalle_result.data.data.length > 0) {
                    listarNumerosCalle_result.data.data.forEach((element) => {
                        var numeroCalle = {
                            valor: element.numero
                        }

                        this.arrayNumerosCalles.push(numeroCalle)
                    })
                }
            }

            processListarNumerosCalle()
        },
        addZero (i) {
            if (i < 10) {
                i = '0' + i
            }
            return i
        },

        reset () {
            this.calleSeleccionada = {}
        },

        buscarCodigoPostal () {
            this.calleSeleccionada = {}
            this.bloquearSelectorNumero = true
            this.calles = []
            this.arrayNumerosCalles = []
            this.ocultarBotonCalleNumeroFormulario = false
            this.isDisabledList = true
            const goCodigoPostal = async () => {
                const config = {
                    timeout: this.espera,
                    headers: {
                        Accept: 'application/json'
                    }
                }
                try {
                    return await this.$axios.get(
                        this.url + '/cobertura/cp/' + this.cp,
                        config
                    )
                } catch (error) {
                    this.contratarShow = false

                    this.go(this.$t('errorConexionApi'), 'error')
                }
            }

            const processCodigoPostalResult = async () => {
                const codigoPostal_result = await goCodigoPostal()
                if (codigoPostal_result.data.data.length > 0) {
                    codigoPostal_result.data.data.forEach((element) => {
                        var calle
                        let nombreConcatenado
                        if (element.tipo_vial === 'AV') {
                            nombreConcatenado = 'AVENIDA ' + element.vial
                        } else {
                            if (element.tipo_vial === 'CL') {
                                nombreConcatenado = 'CALLE ' + element.vial
                            } else {
                                if (element.tipo_vial === 'VT') {
                                    nombreConcatenado = 'VARIANTE ' + element.vial
                                } else {
                                    if (element.tipo_vial === 'PZ') {
                                        nombreConcatenado = 'PLAZA ' + element.vial
                                    } else {
                                        if (element.tipo_vial === 'BO') {
                                            nombreConcatenado = 'BARRIO ' + element.vial
                                        } else { nombreConcatenado = element.vial }
                                    }
                                }
                            }
                        }

                        calle = {
                            nombre: element.vial,
                            nombreConcatenado: nombreConcatenado,
                            poblacion: element.poblacion
                        }
                        this.calles.push(calle)
                    })
                    this.ocultarBotonCalleNumeroFormulario = false
                    this.isDisabledList = false
                } else {
                    this.contratarShow = false

                    this.go(this.$t('orderForm.errores.coberturaCP'), 'error')
                    this.calleSeleccionada = {}
                    this.bloquearSelectorNumero = true
                    this.ocultarBotonCalleNumeroFormulario = false
                    this.calles.push()
                    this.arrayNumerosCalles = []
                    this.reset()
                    this.isDisabledList = true
                }
            }

            processCodigoPostalResult()
        },
        comprobarCP () {
            if (this.cp.length === 5) {
                this.buscarCodigoPostal()
                this.reset()

                this.calles = []
                this.arrayNumerosCalles = []
            }
        }
    }
}
</script>
